<template>
  <Sidebar
      title="Создание учителя"
      @close="close()">

    <!-- Sidebar content -->
    <template v-slot:content>
      <form autocomplete="off" @submit.prevent="create(form)">
        <SvInput
            class="form-item"
            label="Фамилия"
            ref="firstInput"
            placeholder="Введите фамилию..."
            v-model="form.lastName"
            :error="error.lastName"
            @focus="error.lastName = ''"/>

        <SvInput
            class="form-item"
            label="Имя"
            placeholder="Введите имя..."
            v-model="form.firstName"
            :error="error.firstName"
            @focus="error.firstName = ''"/>

        <SvInput
            class="form-item"
            label="Отчество"
            placeholder="Введите отчество..."
            v-model="form.middleName"
            :error="error.middleName"
            @focus="error.middleName = ''"/>

        <SelectSchoolID
            class="form-item"
            loading
            label="Школа"
            :value="form.schoolID"
            @change="form.schoolID = $event"
            :error="error.schoolID"
            @focus="error.schoolID = ''"/>

        <SvInput
            class="form-item"
            label="Email"
            placeholder="Введите email..."
            v-model="form.email"
            :error="error.email"
            @focus="error.email = ''"/>

        <SvInput
            class="form-item"
            label="Телефон"
            placeholder="Введите телефон..."
            v-model="form.phone"
            :error="error.phone"
            @focus="error.phone = ''"/>

        <SvInput
            class="form-item"
            label="Комментарий"
            placeholder="Введите комментарий..."
            v-model="form.comment"
            :error="error.comment"
            @focus="error.comment = ''"/>
      </form>
    </template>

    <!-- Sidebar buttons -->
    <template v-slot:buttons>
      <SvButton border primary @click="create(form)">Создать</SvButton>
    </template>

  </Sidebar>
</template>

<script>
import {mapActions} from "vuex";
import {ParseBackendErr} from "@/helpers";
import {SvInput, SvButton} from "@/components/default";

import Sidebar from "@/components/sidebar/Sidebar";
import SelectSchoolID from "@/components/select/SchoolID";

export default {
  name: "Create",
  components: {
    SvInput,
    SvButton,
    Sidebar,
    SelectSchoolID,
  },
  emits: [
    "close",
  ],
  data() {
    return {
      form: {
        schoolID: 0,
        firstName: "",
        lastName: "",
        middleName: "",
        email: "",
        phone: "",
        comment: "",
      },
      error: {}
    }
  },
  methods: {
    ...mapActions("teachers", [
      "createTeacher",
    ]),

    create(data) {
      this.createTeacher(data).then(() => {
        this.$message.success("Сохранено успешно")
        this.close()
      }).catch(err => {
        this.error = ParseBackendErr(err)
      })
    },

    close() {
      this.form = {}
      this.error = {}
      this.$emit("close")
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.firstInput.focus()
    })
  }
}
</script>

<style scoped>
</style>
