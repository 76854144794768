<template>
  <div class="page">
    <div class="page-header">
      <h3 class="page-title">Учителя</h3>
      <div class="button">
        <SvButton primary @click="activeForm = 'create'">Создать</SvButton>
      </div>
    </div>

    <Filters
        :similar="filter.similar"
        :schoolID="filter.schoolID"
        :loading="loading"
        @changeSimilar="filterBy('similar', $event)"
        @changeSchoolID="filterBy('schoolID', $event)"/>

    <div class="page-data">
      <Table
          :items="teachers"
          :fields="tableFields"
          :loading="loading"
          @rightClick="startUpdate"/>

      <Create
          v-if="activeForm === 'create'"
          @close="close()"/>

      <Update
          v-else-if="activeForm === 'update'"
          :data="selected"
          @close="close()"/>
    </div>

    <SvScrollTrigger @triggered="fetchNextTeachers"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import {SvButton, SvScrollTrigger} from "@/components/default";

import Table from '@/components/table/Table';

import Filters from '@/pages/teachers/filters/Filters';
import Create from '@/pages/teachers/forms/Create';
import Update from '@/pages/teachers/forms/Update';

export default {
  name: "Teachers",
  components: {
    SvButton,
    SvScrollTrigger,
    Table,
    Create,
    Filters,
    Update
  },
  data() {
    return {
      tableFields: [
        {
          header: true,
          prop: "fio",
          label: "ФИО",
        },
        {
          prop: "school.shortname",
          label: "Школа",
          link: (item) => {
            return `/schools?id=${item.id}`
          }
        },
        {
          prop: "phone",
          label: "Телефон",
        },
        {
          prop: "email",
          label: "Email",
        },
        {
          prop: "comment",
          label: "Комментарий",
        },
      ],
      activeForm: "",
    }
  },
  methods: {
    ...mapActions("teachers", [
      "fetchTeachers",
      "fetchNextTeachers",
      "filterTeachers",
      "findTeacher",
      "resetState",
    ]),

    filterBy(key, value) {
      this.filterTeachers({key: key, value: value})
    },

    async startUpdate(teacherID) {
      await this.findTeacher(teacherID).then(() => {
        this.activeForm = "update"
      })

      if (this.$route.query.id !== teacherID) {
        await this.$router.push(`/teachers?id=${teacherID}`)
      }
    },

    close() {
      if (this.$route.query.id) {
        this.$router.push(`/teachers`)
      }

      this.activeForm = ""
    }
  },
  computed: {
    ...mapGetters("teachers", [
      "teachers",
      "selected",
      "filter",
      "loading",
    ]),
  },
  created() {
    this.fetchTeachers().catch(err => {
      console.error(err)
    })

    if (this.$route.query.id) {
      this.startUpdate(Number(this.$route.query.id))
    }
  },
  unmounted() {
    this.resetState()
  }
}
</script>

<style lang="scss" scoped>
.button {
  margin: auto 0;
}
</style>
