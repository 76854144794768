<template>
  <Sidebar
      title="Редактирование учителя"
      @close="close()">

    <!-- Sidebar content -->
    <template v-slot:content>
      <form autocomplete="off" @submit.prevent="update(form)">
        <SvInput
            class="form-item"
            label="Фамилия"
            placeholder="Введите фамилию..."
            v-model="form.lastName"
            :error="error.lastName"
            @focus="error.lastName = ''"/>

        <SvInput
            class="form-item"
            label="Имя"
            placeholder="Введите имя..."
            v-model="form.firstName"
            :error="error.firstName"
            @focus="error.firstName = ''"/>

        <SvInput
            class="form-item"
            label="Отчество"
            placeholder="Введите отчество..."
            v-model="form.middleName"
            :error="error.middleName"
            @focus="error.middleName = ''"/>

        <SelectSchoolID
            class="form-item"
            loading
            label="Школа"
            :value="form.schoolID"
            :school="form.school"
            @change="form.schoolID = $event"
            :name="form.school.shortname"
            :error="error.schoolID"
            @focus="error.schoolID = ''"/>

        <SvInput
            class="form-item"
            label="Email"
            placeholder="Введите email..."
            v-model="form.email"
            :error="error.email"
            @focus="error.email = ''"/>

        <SvInput
            class="form-item"
            label="Телефон"
            placeholder="Введите телефон..."
            v-model="form.phone"
            :error="error.phone"
            @focus="error.phone = ''"/>

        <SvInput
            class="form-item"
            label="Комментарий"
            placeholder="Введите комментарий..."
            v-model="form.comment"
            :error="error.comment"
            @focus="error.comment = ''"/>
      </form>
    </template>

    <!-- Sidebar buttons -->
    <template v-slot:buttons>
      <SvButton border primary @click="update(form)">Сохранить</SvButton>
    </template>
  </Sidebar>
</template>

<script>
import {mapActions} from "vuex";
import {ParseBackendErr} from "@/helpers";
import {SvButton, SvInput} from "@/components/default";

import Sidebar from "@/components/sidebar/Sidebar";
import SelectSchoolID from "@/components/select/SchoolID";

export default {
  name: "Update",
  components: {
    SvButton,
    SvInput,
    Sidebar,
    SelectSchoolID
  },
  emits: [
    "close",
  ],
  props: {
    data: {type: Object, required: true}
  },
  data() {
    return {
      school: {},
      form: JSON.parse(JSON.stringify(this.data)),
      error: {}
    }
  },
  methods: {
    ...mapActions("teachers", [
      "updateTeacher",
    ]),

    update(data) {
      this.updateTeacher(data).then(() => {
        this.$message.success("Сохранено успешно")
        this.close()
      }).catch((err) => {
        this.error = ParseBackendErr(err)
      })
    },

    close() {
      this.form = {}
      this.error = {}
      this.$emit("close")
    },
  },
}
</script>

<style scoped>
</style>
