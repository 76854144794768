<template>
  <div class="filters">
    <SelectSchoolID
        class="filters-field select-school"
        :value="schoolID"
        @change="changeSchoolID"/>

    <SvInput
        class="filters-field search"
        placeholder="Поиск"
        :loading="loading"
        :modelValue="similar"
        @input="changeSimilar"/>
  </div>
</template>

<script>
import {SvInput} from "@/components/default";

import SelectSchoolID from "@/components/select/SchoolID";

export default {
  name: "Filter",
  components: {
    SvInput,
    SelectSchoolID,
  },
  emits: [
    "changeSchoolID",
    "changeSimilar",
  ],
  props: {
    schoolID: {type: Number, required: true},
    similar: {type: String, required: true},
    loading: {type: Boolean, default: false},
  },
  methods: {
    changeSchoolID(evt) {
      this.$emit("changeSchoolID", evt)
    },
    changeSimilar(evt) {
      this.$emit("changeSimilar", evt)
    }
  }
}
</script>

<style lang="scss" scoped>
.filters {
  display: flex;
  align-items: flex-end;
  padding-top: 4px;
  padding-bottom: 16px;

  &-field {
    height: 36px;
    margin-right: 12px;

    &.select-school {
      width: 350px;
    }

    &.search {
      width: 300px;
    }
  }
}
</style>
